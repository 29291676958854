var $ = require('./bower_components/jquery/dist/jquery'),
    responsiceMenu = require('./plugins/responsivemenu'),
    html5Slider = require('./plugins/html5Slider'),
    Hammer = require('./bower_components/hammerjs/hammer'),
    onHash = require('./plugins/onhash'),
    Tip = require('./plugins/tooltip'),
    App = {};

App.slideController = function(list, slide, plusOne) {
    var PlusOne = plusOne || 0;
    if (list.length) {
        list.find('li').eq(0).addClass('current');
        list.find('li a').each(function() {
            Hammer($(this)[0]).off('tap');
            Hammer($(this)[0]).on('tap', function(e) {
                var li = $(e.currentTarget).closest('li');
                list.find('li').removeClass('current');
                li.addClass('current');
                slide.trigger.setToCurrent(li.index() + PlusOne, $(e.currentTarget).closest('div'));
                e.preventDefault();
                e.stopPropagation();
            });
        });
    }
};


App.clients = (function() {
    var open = function(el) {
        var art = el,
            arts = art.closest('.client__section').find('article'),
            innerContent = art.find('.innerContent'),
            tip = innerContent.find('.tip');

        arts.removeClass('open');
        arts.css({
            paddingBottom: 0
        });

        arts.css({
            paddingBottom: 0
        });
        art.addClass('open');
        art.css({
            paddingBottom: innerContent.height() + 180
        });
        innerContent.css({
            top: art.position().top + art.height() + 140
        });
        tip.css({
            left: art.position().left + (art.width() / 2)
        });
    };



    return {
        open: function(el) {
            open(el);
        }
    }
}());

App.init = function() {
    var Slide, Sl_1, Sl_2;

    if ($('.mainmenu__box').length) {
        $('.mainmenu__box').responsiveNav({
            customClass: 'icon-th-menu'
        });
    }

    if ($('.etalage__slide').length) {
        $('.etalage__slide').html5Slider({
            noLabels: true,
            timer: 8,
            bgimage: false
        });
    }

    if ($('header .menu__btn').length) {
        $('header .menu__btn').click(function(e) {
            var el = $(e.currentTarget),
                nav = el.closest('header').find('nav');
            console.log(nav.length);
            nav[(nav.hasClass('show') ? 'remove' : 'add') + 'Class']('show');
            e.preventDefault();
        });
    }

    if ($('.img.round').length) {
        $('.img.round').each(function() {
            $(this).wrap('<div class="deco__image" style="background-image: url(' + $(this).attr('src') + ')"></div>');

        });
    }

    if ($('.email').length) {
        $('.email').each(function() {
            var el = $(this),
                email = el.text().replace(/#/g, '@');
            el.attr('href', 'mailto: ' + email);
            el.text(email);
        }).tooltipper();
        $('.telefoon').tooltipper();

    }

};

$(App.init);